const sunmoon = () => ({
  transitionTimeMS: 300,

  initComponent(value) {
    if (!value) {
      return;
    }

    this.animateSun(parseFloat(value));
    this.maybeHideStartEnd(parseFloat(value));
  },

  animateSun(value) {
    const angle = 180 * value;

    this.$refs.path.setAttribute(
      'transform',
      `rotate(${Math.min(188, Math.max(-2, 178 - angle))}, 64, 68)`,
    );
    this.$refs.sun.setAttribute('transform', `rotate(${Math.min(86, Math.max(-86, 90 - angle))})`);
  },

  maybeHideStartEnd(value) {
    if (value >= 0.03) {
      this.$refs.circleStart.classList.remove('tw-hidden');
    }

    if (value >= 0.98) {
      setTimeout(() => this.$refs.circleEnd.classList.add('tw-hidden'), this.transitionTimeMS);
    }
  },
});

export default sunmoon;
