import tagmanager from '../../../abstracts/tagmanager';

const weather = () => ({
  autocompleteSelect(event) {
    const data = {
      Category: event.detail.attributes['contentType'],
      Skiresort: event.detail.attributes['nameAlias'],
      Summerregion: event.detail.attributes['nameAlias'],
      Federalstate: event.detail.attributes['federalState'],
      Country: event.detail.attributes['country'],
    };

    if (data.Category === 'RegionenSommer') {
      data.Skiresort = null;
    } else {
      data.Summerregion = null;
    }

    tagmanager.event(`weather-search`, data);

    this.$nextTick(() => {
      window.location.href = event.detail.attributes['link'];
    });
  },
});

export default weather;
